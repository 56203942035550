// custom functions
var FE = {
	location: window.location,

    Lightbox: {
      init: function () {
          $('.lightbox').magnificPopup({
              type:'image',
              gallery:{enabled:true}
          });
      }
    },

	MobileMenu: {
		init: function() {
            FE.MobileMenu.indicateSubMenu();
			$('.burger-icon').click(function() {
                $('.nav-main').slideToggle();
            });
		},
        indicateSubMenu: function() {
            $('.nav-main > ul > li').each(function() {
                if ( $(this).find('ul').length ) {
                    $(this).prepend('<span>&gt;</span>');
                }
                $(this).find('span').click(function() {
                    $(this).siblings('ul').slideToggle();
                    $(this).parents('li').toggleClass('open');
                });
            });
        }
	},

    Iframe: {
	    init: function () {
            FE.Iframe.publishMainHeight();
            $('.page a').attr('target', '_blank');

            $('.location-category-filter li').on('click', function() {
                setTimeout(function () { FE.Iframe.publishMainHeight(); }, 1000);
            });
        },
        publishMainHeight: function () {
            var documentHeight = $('.page')[0].scrollHeight;
            var message = 'documentHeight:' + documentHeight;
            //console.log(message);
            parent.postMessage(message, "*");
        }
    },

    ImageSlider: {
        init: function() {
            $('.key-visual .ce-gallery').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                asNavFor: '.slider-for',
                dots: false,
                arrows: false,
                infinite: true,
                speed: 1000,
                fade: false,
                slide: '.ce-row',
                cssEase: 'linear',
                autoplay: true,
                centerMode: false,
                focusOnSelect: false
            });
        }
    },

    LocationCategoryFilter: {
	    init: function () {
            $('.location-category-filter-label').on('click', function() {
                $(this).next('ul').slideToggle();
            });
            $('.location-category-filter li').on('click', function () {
                FE.LocationCategoryFilter.filterLocations( $(this).data('categoryFilter') );
            });
        },
        filterLocations: function (category) {
            $('.location-list li').each(function () {
                if ( $(this).data('categories').includes(category) ) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
                //console.log( $(this).data('categories') );
            });
        }
    },

    Video: {
        userConsentAvailable: false,
        init: function () {
            document.querySelectorAll('.video-keep-data-privacy[data-video-type="youtube"]').forEach(function (videoItem) {
                //videoItem.addEventListener('click', FE.Video.checkVideoLoading);
                videoItem.addEventListener('click', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    FE.Video.loadVideo(e.currentTarget);
                });
            });
        },
        loadVideo: function (videoItem) {
            const videoId = videoItem.dataset.videoId;
            const videoArea = videoItem.parentElement;

            FE.Video.addUserConsent(videoArea, videoId);

            console.log(videoArea);

            // check potential permission via consent manager or any other persistent data such as session storage or cookie
            if ( FE.Video.userConsentAvailable) {
                videoArea.innerHTML = FE.Video.getYouTubeEmbedCode(videoId);
            } else {
                videoItem.nextElementSibling.classList.add('show');
            }

        },
        embedVideo: function (videoArea, videoId) {
            videoArea.innerHTML = FE.Video.getYouTubeEmbedCode(videoId);
        },
        getVimeoEmbedCode: function (videoId) {
            return '<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/' + videoId + '?h=96a48e54c0&byline=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>';
        },
        getYouTubeEmbedCode: function (videoId) {
            return '<iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/' + videoId + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        },
        addUserConsent: function (videoArea, videoId) {
            const videoConsentHtml = `An dieser Stelle laden wir ein externes Video von YouTube.<br>
                                        Mir ist bewusst, dass dabei Daten mit den YouTube Servern ausgetauscht werden.<br>
                                        Weitere Informationen zu den <a href="https://policies.google.com/technologies/cookies?hl=de" target="_blank" rel="noopener">YouTube Datenschutzbedingungen</a>.
                                        <div class="btn-group"><button class="btn-video-confirm">Geht klar</button><button class="btn-video-deny">möchte ich nicht</button>`;

            const videoConsent = document.createElement('div');
            videoConsent.classList.add('external-video-consent');
            videoConsent.innerHTML = `<div class="external-video-consent-inner">${videoConsentHtml}</div>`;
            videoArea.append(videoConsent);
            videoArea.querySelector('.btn-video-confirm').addEventListener('click', function (e) {
                FE.Video.embedVideo(videoArea, videoId);
            });
            videoArea.querySelector('.btn-video-deny').addEventListener('click', function (e) {
                e.target.closest('.external-video-consent').classList.remove('show');
            });
        }
    }

};

// init custom functions
(function ($, window, document, undefined) {

	$(function () {
        FE.Lightbox.init();
		FE.MobileMenu.init();
        if ( $('.key-visual .ce-row').length ) {
            FE.ImageSlider.init();
        }
        if ( $('.location-category-filter').length ) {
            FE.LocationCategoryFilter.init();
        }
        // check if page is iframe version
        if ( $('.page.iframe').length ) {
            FE.Iframe.init();
        }

        if ( document.querySelector('.video-keep-data-privacy')) {
            FE.Video.init();
        }
	});

}(jQuery, window, document));